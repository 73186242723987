footer {
	position: relative;
	color: white;
	background-color: black;
	padding-top: 90px;
	z-index: 1;

	.container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		max-width: 1210px;
	}

	.footer-coll-1,
	.footer-coll-2 {
		flex: 0 1 50%;
	}

	.footer-coll-1 {
		text-align: center;

		.footer-title {
			text-align: center;
			font-size: 34px;
			font-weight: 400;
			text-transform: uppercase;
		}

		.footer-signup-btn {
			position: relative;
			outline: none;
			border: none;
			padding: 10px 23px;
			width: 444px;
			height: 86px;
			margin-top: 17px;
			background-color: transparent;
			background-image: url("../../images/bt-signup.svg");
			background-position: center;
			background-size: 100% 100%;

			&::before,
			&::after {
				font-family: "Aleo";
				font-size: 30px;
				line-height: 100%;
				color: #ffc86d;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				text-transform: uppercase;
				background-size: 100% 100%;
			}

			&::before {
				content: "";
				opacity: 0;
				background-image: url("../../images/bt-signup-hover.svg");
				transition: opacity 0.3s;
			}

			&::after {
				content: "Sign Up for Newsletter";
				text-transform: uppercase;
				font-weight: 300;
				z-index: 100;
			}

			&:hover {
				&::before {
					opacity: 1;
				}
			}

			&:active {
				&::before {
					background-image: url("../../images/bt-signup-focus.svg");
				}
			}
		}
	}

	.footer-coll-2 {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		.footer-title {
			flex: 1 0 100%;
			text-align: center;
			font-size: 34px;
			font-weight: 400;
			text-transform: uppercase;
		}

		.footer-link-icon {
			display: inline-block;
			width: 47px;
			height: 47px;
			background-position: center;
			background-repeat: no-repeat;
			margin: 0px 16px;
			position: relative;
			color: transparent;
			transition: opacity 0.3s;

			&::after {
				content: "";
				position: absolute;
				top: 21px;
				right: -22px;
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background-color: white;
			}
			&:hover {
				opacity: 0.8;
				transition: opacity 0.3s;
			}
		}

		.footer-link-icon-1 {
			background-image: url("../../images/twitter.svg");
		}

		.footer-link-icon-2 {
			background-image: url("../../images/discord.svg");
		}

		.footer-link-icon-3 {
			background-image: url("../../images/medium.svg");
			background-size: contain;
		}

		.footer-link-icon-4 {
			background-image: url("../../images/youtube.svg");

			&::after {
				display: none;
			}
		}

		.footer-elixir {
			display: none;
			flex: 1 0 100%;
			text-align: center;
		}
	}

	.footer-logo {
		width: 355px;
		display: inline-block;
		// margin-top: 58px;
	}

	.footer-coll-3 {
		flex: 0 1 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 75px;

		.footer-fore-img {
			background-image: url("../../images/fore.png");
			background-repeat: no-repeat;
			width: 42px;
			height: 44px;
			background-position: center center;
			background-size: 100%;
			display: none;
		}

		.footer-coll-3-wrap {
			padding-left: 16px;

			.footer-text-1 {
				font-family: "Aleo";
				font-size: 24px;
				margin-bottom: 0px;
				text-align: center;
			}

			.footer-text-2 {
				font-size: 10px;
				margin-bottom: 0px;
			}
		}
	}

	@media screen and (max-width: 960px) {
		.container {
			flex-direction: column;
			align-items: center;
			padding-left: 0px;
			padding-right: 0px;
		}

		.footer-coll-2 {
			margin-top: 36px;
		}

		.footer-coll-1 {
			.footer-signup-btn {
				max-width: 360px;
			}
		}

		.footer-coll-1 .footer-signup-btn::before,
		.footer-coll-1 .footer-signup-btn::after {
			font-size: 22px;
		}
	}

	@media screen and (max-width: 730px) {
		padding-top: 19px;

		.container {
			flex-direction: column;
			justify-content: center;
		}

		.footer-coll-2 {
			margin-top: 44px;

			.footer-link-icon {
				width: 62px;

				&.footer-link-icon-3 {
					background-size: auto;
				}
			}
		}

		.footer-logo {
			margin: 94px auto 0;
		}
	}

	@media screen and (max-width: 420px) {
		.footer-coll-2 {
			.footer-link-icon {
				width: 55px;
			}
		}

		.footer-coll-3 {
			padding: 0 5px;

			.footer-coll-3-wrap {
				.footer-text-1 {
					font-size: 23px;
				}
			}
		}
	}
}
