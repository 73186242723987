.news {
	background-image: url("../../images/features-bg.png");
	position: relative;

	.news-anker {
		position: absolute;
		top: -93px;
	}

	.container {
		padding: 91px 15px;
	}

	.news-title {
		margin: 0 0 41px 0;
		font-size: 34px;
		font-weight: 400;
		text-transform: uppercase;
		padding: 51px 0px;
		background-image: url("../../images/texture-3.png");
		background-repeat: no-repeat;
		background-position: center;
	}

	.news-wrap {
		margin-top: 24px;
	}

	.news-item {
		display: flex;
		flex-direction: column;
		flex: 1 0;
		max-width: 328px;
		min-width: 300px;
		text-align: center;
		background-color: #ffefcf;
		border-radius: 5px;
		overflow: hidden;
		margin-bottom: 10px;
		transform: scale(1, 1);
		transition: transform 0.5s;
		cursor: pointer;
		box-shadow: 6px 14px 42.6px rgba(0, 0, 0, 0.15);

		&:hover {
			transform: scale(1.06, 1.06);
		}

		&-img {
			background-size: cover;
			background-position: center -2px;
			background-repeat: no-repeat;
			height: 178px;
		}

		&-date {
			font-family: "Aleo";
			font-size: 14px;
			text-align: left;
			text-transform: uppercase;
			padding: 16px 25px 0px;
			margin-bottom: 7px;
		}

		&-title {
			font-family: "Aleo";
			font-size: 20px;
			text-transform: uppercase;
			text-align: center;
			padding: 5px 25px 0px;
			line-height: 24px;
			margin-bottom: 20px;
			min-height: 77px;
		}

		&-1 {
			.news-item-img {
				background-image: url("../../images/news-gdc-2025.png");
			}
		}
		&-2 {
			.news-item-img {
				background-image: url("../../images/news-eth-denver.webp");
			}
		}
		&-3 {
			.news-item-img {
				background-image: url("../../images/news-1.png");
			}
		}
		&-4 {
			.news-item-img {
				background-image: url("../../images/news-2.png");
			}
		}
	}

	.view-all {
		margin: 30px auto;
	}

	&::before,
	&::after {
		content: "";
		display: block;
		position: absolute;
		z-index: 1;
		top: 31px;
		background-image: url("../../images/texture-1.png");
		background-repeat: no-repeat;
		background-position: center;
		background-size: auto 28px;
		width: 100%;
		height: 28px;
	}

	&::after {
		top: auto;
		// content: '';
		// display: block;
		// position: absolute;
		bottom: 31px;
		// background-image: url('../../images/border-1.png');
		// background-repeat: no-repeat;
		// width: 100%;
		// height: 28px;
		// background-position: center bottom;
		// background-size: auto 14px;
		// z-index: 1;
	}

	.news-wrap-desctop {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 2%;
	}

	.keen-slider {
		display: none;
	}

	@media screen and (max-width: 740px) {
		.container {
			padding: 10px 0px 86px;
		}
		// .news-wrap-desctop {
		// 	display: none;
		// }

		.keen-slider {
			display: flex;

			.news-item {
				margin: 0px auto 10px;
			}
		}

		.news-item {
			&:hover {
				transform: none;
			}
		}
	}
}
